// all functions for the purpose of altering a button in our app should be here

@mixin isHoriantalIcon($justifyContent, $flexDirection) {
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
  align-items: center;
  display: flex;
  height: 100%;
}

@mixin rippleColor($buttonColor, $rippleColor) {
  &:hover {
    background: $buttonColor
      radial-gradient(circle, transparent 1%, $buttonColor 1%) center/15000%;
  }
  &:active {
    background-color: $rippleColor;
    background-size: 100%;
    transition: background 0s;
  }
}

@mixin rippleColorMenu($buttonColor, $rippleColor) {
  &:hover {
    background: $buttonColor
      radial-gradient(circle, transparent 1%, $buttonColor 1%) center/15000%;
    height: 100%;
    width: 100%;
  }
  &:active {
    background-color: $rippleColor;
    background-size: 100%;
    transition: background 0s;
  }
}
