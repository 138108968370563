@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
        ),
        typography: mat.define-typography-config(),
        density: 0,
    )
);

@include mat.all-component-themes($my-theme);

/* You can add global styles to this file, and also import other style files */
@import './app/styles/Mixers/buttonMixer.scss';
@import './assets/branding/styles/colors.scss';

html,
body {
    scrollbar-width: none; /* Firefox 64 */
    font-family: 'Roboto', sans-serif;
    user-select: none;
    overflow: hidden;
    height: 100%;
    padding: 0;
    margin: 0;

    .ripple {
        @include rippleColor($grey, $secondaryGrey);
        background-position: center;
        transition: background 0.5s;
    }

    .ripple-secondary {
        @include rippleColor($primary, $primary-hover);
        background-position: center;
        transition: background 0.5s;
    }

    .rippleMenu {
        @include rippleColorMenu($grey, $secondaryGrey);
        background-position: center;
        // transition: background 0.5s;

        // width: 99% !important;
        // height: 99% !important;
        // display: flex !important;
        // align-items: center !important;
        // justify-content: center !important;
        // background: #ebebeb radial-gradient(circle, transparent 0%, #ebebeb 0%)
        //   center/15000% !important;
    }
}

::-webkit-scrollbar {
    display: none;
    scrollbar-width: none; /* Firefox 64 */
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    scrollbar-width: none; /* Firefox 64 */
    width: 100%;
}

.rippleMenu {
    @include rippleColorMenu($grey, $secondaryGrey);
    width: 99%;
    height: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebeb radial-gradient(circle, transparent 1%, #ebebeb 1%)
        center/15000%;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    scrollbar-width: none; /* Firefox 64 */
}

$salmon: #f25648;

$primaryColor: $salmon;
$secondaryColor: white;

#outdated {
    font-family: 'Open Sans', 'Segoe UI', sans-serif;
    position: absolute;
    background-color: $primaryColor;
    color: $secondaryColor;
    display: none;
    overflow: hidden;
    left: 0;
    position: fixed;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    padding: 0 24px 24px 0;

    &.fullscreen {
        height: 100%;
    }
    .vertical-center {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
    h6 {
        font-size: 25px;
        line-height: 25px;
        margin: 12px 0;
    }

    p {
        font-size: 12px;
        line-height: 12px;
        margin: 0;
    }

    #buttonUpdateBrowser {
        border: 2px solid $secondaryColor;
        color: $secondaryColor;
        cursor: pointer;
        display: block;
        margin: 30px auto 0;
        padding: 10px 20px;
        position: relative;
        text-decoration: none;
        width: 230px;
        &:hover {
            background-color: $secondaryColor;
            color: $primaryColor;
        }
    }

    .last {
        height: 20px;
        position: absolute;
        right: 70px;
        top: 10px;
        width: auto;
        display: inline-table;
    }

    .last[dir='rtl'] {
        left: 25px !important;
        right: auto !important;
    }

    #buttonCloseUpdateBrowser {
        display: none;
    }
}

.gm-style-iw .gm-style-iw-c,
.gm-style .gm-style-iw {
    padding: 0 !important;
    width: 22rem;
}

.gm-style-iw .gm-style-iw-c,
.gm-style .gm-style-iw {
    padding: 0 !important;
    width: 22rem;
}

.destination-marker {
    display: flex;
    justify-content: center;

    .destination-label {
        background: white;
        border: 1px solid black;
        padding: 1rem;
        height: fit-content;

        display: flex;
        left: 8rem;
        width: max-content;
        flex-direction: column;
    }
}

// 4k resoultions
@media screen and (min-width: 3840px), (min-height: 2160px) {
    .gm-style-iw .gm-style-iw-c,
    .gm-style .gm-style-iw {
        padding: 0 !important;
        width: 50rem;
    }

    .destination-marker {
        img {
            width: 14rem !important;
        }

        .destination-label {
            font-size: 3rem !important;
        }
    }
}
