$primary: black;
$primary-hover: #222121;
$secondary: black;
$grey: #ebebeb;
$transparent: #5573cc;
$secondaryGrey: #bebcbc;
$white: white;
$concourseColorMarine: #4089bd;
$concourseRed: #d00607;
$concourseYellow: #e9cd2e;
$lightGrey: #ebebeb;
$headerColor: #ffffff;
$logoColor: none;
$headerText: black;
$statusGreen: #0f7241;
$green: green;
$flightStatusGreen: #00b900;
$flightStatusRed: #ef0d0d;
$flightStatusOrange: #f7a816;
$black: black;
$sideInfoTitleBgColor: black;
$buttonIconColorUnselected: black;
$buttonTextColorUnselected: black;
$sideInfoFlightItemBgColor: black;
$sideInfoFlightItemBgColorSelected: black;
$buttonIconColorSelected: #00a6ce;
$buttonTextColorSelected: white;
$buttonIconColor: #00a6ce;
$loaderBackground: #00a6ce;
$lightBlue: #00a6ce;
$loaderText: white;
$dialogButtonColor: black;
$ratingColor: black;
$poiInformationTabColor: #00a6ce;
$poiInformationTextIconColor: white;
$filterLightBlue: brightness(0) saturate(100%) invert(52%) sepia(67%)
  saturate(4166%) hue-rotate(160deg) brightness(97%) contrast(101%);
$filterWhite: brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(22%)
  hue-rotate(225deg) brightness(105%) contrast(108%);
$filterBlue: brightness(0) saturate(100%) invert(10%) sepia(71%) saturate(4502%)
  hue-rotate(226deg) brightness(100%) contrast(94%);
$filterBlack: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(5%)
  hue-rotate(14deg) brightness(88%) contrast(101%);
$laxTVSideMenuColor: #e8e8e8;
$laxTVTextColor: black;
$laxTVActiveColor: #00a6ce;
$laxTVContentColor: white;
$laxTVSideMenuHeaderBackground: black;
$laxTVSideMenuHeaderText: white;
$laxTvActiveTextColor: white;
$selectedVideoHeaderBackgroundColor: black;
$selectedVideoTextColor: white;
$backButtonColor: white;

$controller-container-background: #f6f6f6;
$active-route-step-color: #e0dddd;

$emergency-background: #aa0000;
$emergency-divider-border: #b34a4a;

$surveyButtonColor: #00a5ca;
